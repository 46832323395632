import { Route, Switch } from "react-router-dom";
import ChangePass from "../../pages/ChangePass";
import ChangePassword from "../../pages/ChangePassword";
import CookiePolitcs from "../../pages/Legal/CookiePolitcs";
import GlobalPolitcs from "../../pages/Legal/GlobalPolitcs";
import InfoAdvices from "../../pages/Legal/InfoAdvices";
import LegalAdvice from "../../pages/Legal/LegalAdvice";
import TermsOfUse from "../../pages/Legal/TermsOfUse";
import Login from "../../pages/Login";

const PublicRoute = () => (
    <Switch>
        <Route exact path="/" component={() => <Login />} />
        <Route exact path="/changepass" component={() => <ChangePass />} />
        <Route
            exact
            path="/redefine-password/:id"
            component={() => <ChangePassword />}
        />
        <Route exact path="/legal/termos-de-uso" component={() => <TermsOfUse />} />
        <Route exact path="/legal/aviso-legal" component={() => <LegalAdvice />} />
        <Route exact path="/legal/politicas-de-cookies" component={() => <CookiePolitcs />} />
        <Route exact path="/legal/aviso-de-informaçao" component={() => <InfoAdvices />} />
        <Route exact path="/legal/politicas-globais" component={() => <GlobalPolitcs />} />
        <Route exact path="/legal/politicas-lgpd" component={() => {
            window.location.href = 'https://www.bureauveritas.com.br/sites/g/files/zypfnx206/files/media/document/Prote%C3%A7%C3%A3o%20de%20Dados%20Brasil%20(4).pdf';
            return null;
        }}/>
    </Switch>
);

export default PublicRoute;
