export default function TermsOfUse() {
  return (
    <main className="w-full flex py-12 justify-center h-screen overflow-auto bg-[#ebebeb]">
      <section className="max-w-[540px] text-[#212529] md:max-w-[720px] lg:max-w-[960px] xl:max-w-[1140px]">
        <h1 className="text-center  mb-12 text-[2.5rem] font-medium">Termos de Uso</h1>
        <p className="box-border mb-4">Qualquer acesso e uso do site (o “Site”) implica a aceitação do usuário (o “Usuário”) dos seguintes termos de uso. Se o acesso ao site for concedido ao usuário com um login e uma senha, estes termos de uso serão regidos pelos termos de uso específicos relevantes do site ou pelas condições gerais de serviço do Bureau Veritas relevantes, acessíveis no site antes ao acesso restrito ou na primeira conexão.</p>
        <p className="box-border mb-4"><strong>Uso do site:</strong> O Site pode fornecer informações sobre a empresa relevante do Bureau Veritas, seus produtos e serviços (o “Conteúdo”). O Utilizador deverá aceder ao Website apenas para fins pessoais. O Conteúdo é protegido por direitos de propriedade intelectual que pertencem ao Bureau Veritas ou a suas afiliadas ou que são licenciados. Assim, qualquer reprodução, representação, extração, modificação, divulgação ou exploração total ou parcial do Site, ou de qualquer um dos elementos do Conteúdo (como textos, imagens, fotos, gráficos, dados, arquitetura, código fonte, software e bases de dados), em qualquer meio, é estritamente proibido, a menos que previamente autorizado por escrito pelo Bureau Veritas ou suas afiliadas.</p>
        <p className="box-border mb-4">Além disso, O Bureau Veritas tem o direito de modificar o Conteúdo a qualquer momento sem aviso prévio.</p>
        <p className="box-border mb-4">O Usuário estritamente não deve:</p>
        <ul className="pl-5 mb-4 list-disc">
          <li>Fazer qualquer deterioração, download não autorizado, ataque DDoS, apropriação indevida do Conteúdo, tentativa de burlar ou danificar dispositivos de segurança, phishing, introdução fraudulenta na infraestrutura do Bureau Veritas, etc;</li>
          <li>Prejudicar a integridade ou disponibilidade do Site e/ou os interesses, reputação e/ou direitos exclusivos do Bureau Veritas;</li>
          <li>Transmitir ou divulgar através do Site informações ilegais, contrárias à política pública ou difamatórias.</li>
        </ul>
        <p className="box-border mb-4">O acesso e uso do Site terminará caso o Usuário não cumpra estes termos de uso.</p>
        <p className="box-border mb-4"><strong>Disponibilidade:</strong> O Site funciona 24/24 horas e 7/7 dias. No entanto, se ocorrer um evento de força maior, o site estiver sobrecarregado ou indisponível devido às redes de comunicação eletrônica, o acesso a todo ou parte do site pode ser retardado ou interrompido. Além disso, o Bureau Veritas pode realizar manutenções corretivas ou preventivas no site ou nas instalações de hospedagem. Assim, o Bureau Veritas não garante a disponibilidade ou o acesso contínuo ao site.</p>
      </section>

    </main>
  )
}