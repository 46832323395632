export default function LegalAdvice() {

  return (
    <main className="w-full flex py-12 justify-center h-screen overflow-auto bg-[#ebebeb]">
      <section className="max-w-[540px] text-[#212529] md:max-w-[720px] lg:max-w-[960px] xl:max-w-[1140px]">
        <h1 className="text-center  mb-12 text-[2.5rem] font-medium">Aviso Legal</h1>
        <div className="box-border mb-4 ">
          <p>Editora: Os subdomínios&nbsp;</p>
          <a
            className=" hover:text-blue-800"
            title="Site https://auditor.bureauveritas.com.br/"
            href="https://auditor.bureauveritas.com.br/"
            target="_blank"
            rel="noreferrer"
          >
            https://auditor.bureauveritas.com.br/
          </a>,
          <a
            className="hover:text-blue-800"
            title="Site https://cliente.bureauveritas.com.br/"
            href="https://cliente.bureauveritas.com.br/"
            target="_blank"
            rel="noreferrer"
          >
            https://cliente.bureauveritas.com.br/
          </a>,
          <a
            className="hover:text-blue-800"
            title="Site https://admin.bureauveritas.com.br/"
            href="https://admin.bureauveritas.com.br/ "
            target="_blank"
            rel="noreferrer"
          >
            https://admin.bureauveritas.com.br/
          </a>,
          <a
            className="hover:text-blue-800"
            title="Site https://fabrica.bureauveritas.com.br/"
            href="https://fabrica.bureauveritas.com.br/"
            target="_blank"
            rel="noreferrer"
          >
            https://fabrica.bureauveritas.com.br/
          </a>,
          <p>
            são publicados pela empresa DEVIO SOFTWARE INTELIGENTES LTDA, registrada no Registro Comercial de São Paulo/SP - Brasil sob o número 32.973.740/0001-09 com sede na Avenida Brig Faria Lima, 2369, Conj 1102 - Jardim Paulistano, São Paulo/SP.
          </p>
        </div>
        <p className="box-border mb-4">CEP 01452-922.</p>
        <p className="box-border mb-4">
          Tel.:
          <a
            className="hover:text-blue-800"
            title="Telefone 551131647007"
            href="tel:551131647007"
            rel="noreferrer"
          >
            +55 (11) 3164-7007
          </a>
        </p>
        <p className="box-border mb-4">E-mail:&nbsp;
          <a
            className="hover:text-blue-800"
            title="E-mail para: hey@devio.com.br"
            href="mailto:hey@devio.com.br"
            rel="noreferrer"
          >
            hey@devio.com.br
          </a>
        </p>
      </section>
    </main>
  )
}