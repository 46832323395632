import { createContext, useEffect, useState } from "react";

type InfoProps = {
    refrashList: boolean;
    setRefrashList: (state: boolean) => void;
    reloadData: boolean;
    setReloadData: (state: boolean) => void;
    loading: boolean;
    setLoading: (state: boolean) => void;
};

const DEFAULT_VALUE = {
    refrashList: false,
    setRefrashList: () => {},
    reloadData: false,
    setReloadData: () => {},
    loading: false,
    setLoading: () => {},
};

const InfoContext = createContext<InfoProps>(DEFAULT_VALUE);

const InfoContextProvider = ({ children }: any) => {
    const [refrashList, setRefrashList] = useState(DEFAULT_VALUE.refrashList);
    const [reloadData, setReloadData] = useState(DEFAULT_VALUE.reloadData);
    const [loading, setLoading] = useState<boolean>(DEFAULT_VALUE.loading);

    return (
        <InfoContext.Provider
            value={{
                refrashList,
                setRefrashList,
                reloadData,
                setReloadData,
                loading,
                setLoading,
            }}
        >
            {children}
        </InfoContext.Provider>
    );
};

export { InfoContextProvider };
export default InfoContext;
