/* eslint-disable eqeqeq */
import { HiChevronLeft, HiChevronRight } from "react-icons/hi";

type PaginationProps = {
    initPage?: number;
    totalPage: number;
    page: number;
    setPage: (state: number) => void;
    path?: string;
};

export function Pagination({
    initPage,
    totalPage,
    page,
    setPage,
    path,
}: PaginationProps) {

    function nextPage(currentPage: number, middleNumber: boolean = false) {
        if (currentPage > totalPage) {
            setPage(totalPage);
        } else {
            setPage(currentPage);
        }
    }

    function previousPage(currentPage: number, middleNumber: boolean = false) {
        if (currentPage < 1) {
            setPage(1);
        } else {
            setPage(currentPage);
        }
    }

    function* range(inicio: number, fim: number, incremento = 1) {
        if (fim === undefined) {
            [inicio, fim] = [0, inicio];
        }

        if (fim > inicio) {
            if (incremento < 0) incremento *= -1;
            for (var i = inicio; i < fim; i += incremento) {
                yield i;
            }
        } else {
            if (incremento > 0) incremento *= -1;
            for (var idx = inicio; idx > fim; idx += incremento) {
                yield idx;
            }
        }
    }

    // Lógica para determinar quais páginas mostrar
    let pagesToDisplay: (number | string)[] = [];
    if (totalPage <= 7) {
        pagesToDisplay = Array.from(range(1, totalPage + 1));
    } else if (page <= 4) {
        pagesToDisplay = [1, 2, 3, 4, 5, '...', totalPage];
    } else if (page >= totalPage - 3) {
        pagesToDisplay = [1, 2, '...', totalPage - 4, totalPage - 3, totalPage - 2, totalPage - 1, totalPage];
    } else {
        pagesToDisplay = [1, 2, '...', page - 1, page, page + 1, '...', totalPage - 1, totalPage];
    }

    return (
        <>
            <div className="flex items-center justify-center w-full gap-2 text-white">
                <button type="button">
                    <div
                        className="flex items-center justify-center w-6 h-6 text-black bg-white rounded-full cursor-pointer text-darkPurple"
                        onClick={() => previousPage(page - 1 >= 1 ? page - 1 : 1)}
                    >
                        <HiChevronLeft size={24} />
                    </div>
                </button>
                <div className="flex gap-1 bg-white rounded-xl">
                    {pagesToDisplay.map((itemPage, index) => (
                        <div
                            key={index}
                            className={
                                itemPage === '...'
                                    ? "rounded-xl text-darkBlue w-7 h-7 flex  items-center justify-center cursor-pointer text-black"
                                    : page == itemPage
                                        ? "rounded-full bg-stone-600 bg-darkBlue w-7 h-7 flex items-center justify-center text-black"
                                        : "rounded-xl text-darkBlue w-7 h-7 flex items-center justify-center cursor-pointer text-black"
                            }
                            onClick={() => nextPage(typeof itemPage === 'number' ? itemPage : page, true)}
                        >
                            {itemPage}
                        </div>
                    ))}
                </div>
                <button type="button">
                    <div
                        className="flex items-center justify-center w-6 h-6 text-black bg-white rounded-full cursor-pointer text-darkPurple"
                        onClick={() => {
                            nextPage(page + 1 <= totalPage ? page + 1 : totalPage);
                        }}
                    >
                        <HiChevronRight size={24} />
                    </div>
                </button>
            </div>
        </>
    );
}
