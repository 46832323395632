export default function GlobalPolitcs() {

  return (
    <main className="w-full flex  justify-center h-screen overflow-auto bg-[#ebebeb]">
      <section className="max-w-[540px] py-12 text-[#212529] md:max-w-[720px] lg:max-w-[960px] xl:max-w-[1140px]">
        <h1 className="text-center  mb-12 text-[2rem] font-medium">
          GRUPO BUREAU VERITAS. POLÍTICA DE PROTECÇÃO DE DADOS PARA UTILIZADORES
        </h1>
        <h2 className="text-center  mb-12 text-[1.5rem] font-medium">
          (Última atualização: 18 de Maio de 2021)
        </h2>
        <p className="box-border mb-4">O Bureau Veritas (doravante "<b>Bureau Veritas</b>", "<b>nós</b>"", "<b>nosso</b>", "<b>our</b>") reconhece a importância da protecção efectiva e significativa dos Dados Pessoais ao recolher e tratar os Dados Pessoais dos seus Utilizadores (conforme definido infra).</p>
        <p className="box-border mb-4">
          Damos grande valor à integridade e comprometemo-nos em construir relacionamentos fortes e duradouros consigo, baseados na confiança e no mútuo benefício. A protecção da privacidade é essencial para nós. A presente Política de Protecção de Dados Pessoais expressa o forte compromisso do Grupo Bureau Veritas de respeitar e proteger os Dados Pessoais de cada indivíduo e de garantir o cumprimento internacional com as Leis de Protecção de Dados
        </p>
        <p className="box-border mb-4">
          Esta Política de Protecção de Dados Pessoais abrange todos os Dados Pessoais recolhidos e tratados pelo Bureau Veritas em todo o mundo.
        </p>
        <p className="box-border mb-4">
          Esta Política de Protecção de Dados Pessoais destina-se a informà-lo sobre os tipos deDados Pessoais que podemos recolher ou manter sobre si, como os utilizamos, com quem os partilhamos, como os protegemos e mantemos seguros, e os seus direitos sobre os seus Dados Pessoais.
        </p>
        <p className="box-border mb-4">
          Tenha em consideração que podemos actualizar esta Política de Protecção de Dados Pessoais a qualquer momento para adaptà-la a possíveis novas pràticas e ofertas de serviços. Nesse caso, alteraremos a data da 'última atualização' e indicaremos a data em que as alterações foram feitas. Esta Política de Protecção de Dados Pessoais està anexada aos contratos que celebramos com nossos clientes, prestadores de serviços ou parceiros. Ela também està disponível nos websites e soluções do Bureau Veritas.
        </p>
        <p className="box-border mb-4">
          Recomendamos que reveja periodicamente as possíveis actualizações desta Política de Protecção de Dados Pessoais disponível em
          <a
            className="hover:text-blue-800"
            title="Site https://personaldataprotection.bureauveritas.com/privacypolicy"
            href=" https://personaldataprotection.bureauveritas.com/privacypolicy"
            target="_blank"
            rel="noreferrer"
          >
            https://personaldataprotection.bureauveritas.com/privacypolicy
          </a>.
        </p>
        <p className="box-border mb-4">
          "<b>Dados pessoais</b>"  significa qualquer informação ou partes de informação que possam identificà-lo directa ou indirectamente. Isso significa que os Dados Pessoais incluem informações como e-mail / moradas, nomes de utilizadores, conteúdo gerado pelo utilizador, informações financeiras, endereço IP, etc.
        </p>
        <p className="box-border mb-4">
          "<b>Utilizador (es)</b>" significa quaisquer potencial (ais) cliente(s), clientes, fornecedores, parceiros, prestadores de serviços, candidatos e, em geral, qualquer pessoa que esteja em contacto connosco (doravante 'você' ou 'seu').
        </p>
        <p className="box-border mb-4">
          "<b>Tratamento</b>"significa qualquer operação ou conjunto de operações que são realizadas com Dados Pessoais ou em conjuntos de Dados Pessoais, por meios automatizados ou não automatizados, tais como recolha, registo, organização, estruturação, armazenamento, adaptação ou alteração, recuperação, consulta, utilização, divulgação por transmissão, divulgação ou disponibilização, alinhamento ou combinação, restrição, apagamento ou destruição.
        </p>
        <p className="box-border mb-4 underline">
          <b>1. Quais são os princípios para o tratamento de Dados Pessoais?</b>
        </p>
        <p className="box-border mb-4">
          Cada entidade legal do Grupo Bureau Veritas que recolhe ou trata os seus Dados Pessoais com finalidade comercial actua como o “responsável pelo tratamento”. De acordo com as leis de protecção de dados, deve determinar as finalidades e os meios de tratamento dos seus dados pessoais. Isso significa que somos responsàveis pelos dados pessoais que partilha connosco.
        </p>
        <p className="box-border mb-4">
          Tratamos os seus dados pessoais em conformidade com os requisitos legais aplicàveis e, em particular, o Regulamento Geral de Protecção de Dados Europeu 2016/679 ('RGPD') de 27 de Abril de 2016 aplicàvel a partir de 25 de Maio de 2018 na União Europeia.Tratamos os seus dados pessoais em conformidade com as leis e regulamentos aplicàveis, requisitos legais e, em particular, o Regulamento Geral Europeio de Proteçcão de Dados 2016/679 ('RGPD') datado de 27 de abril de 2016, a Lei Geral de Protecção de Dados do Brasil («LGPD») de 2018, a Lei de Protecção de Informações Pessoais da África do Sul (Lei POPIA) de 2013, a Lei Australiana de Privacidade de 1988, a Lei de Cibersegurança da República Popular da China de 2016, a Lei Chinesa de Protecção de Informações Pessoais de 2020, etc.
        </p>
        <p className="box-border mb-4">
          Em particular, comprometemo-nos a:
        </p>
        <ul className="pl-5 mb-4 list-disc">
          <li>Obter e a tratar os seus Dados Pessoais de forma justa e legal;</li>
          <li>Obter os seus Dados Pessoais para finalidades específicas, explícitas e legítimas, e não tratà-los posteriormente de maneira incompatível com essas finalidades;</li>
          <li>Tratar somente os Dados Pessoais que sejam adequados, relevantes e não excessivos em relação às finalidades para as quais foram obtidos e seu tratamento subsequente;</li>
          <li>Assegurar que os seus Dados Pessoais sejam precisos, completos e, quando necessàrio, mantidos actualizados;</li>
          <li>Guardar os seus Dados Pessoais por um período não superior ao necessàrio para os fins para os quais foram obtidos e tratados, e de acordo com a legislação aplicàvel e disposições específicas para a prestação dos serviços.</li>
        </ul>
        <p className="box-border mb-4 underline">
          <b>2. Que Dados Pessoais recolhemos, porquê e como os usamos?</b>
        </p>
        <p className="box-border mb-4">
          Para que tenha uma visão mais clara sobre como tratamos os seus Dados Pessoais, criàmos uma tabela na qual pode encontrar informações pesquisando com o contexto / finalidade da recolha de dados.
        </p>
        <ul className="pl-5 mb-4 list-decimal">
          <li>Coluna 1 - Em que contexto os seus dados são recolhidos? Esta coluna explica qual a actividade ou contexto em que està envolvido quando tratamos ou recolhemos os seus Dados Pessoais.</li>
          <li>Coluna 2 - Que tipos de dados pessoais podemos manter sobre si? Esta coluna explica os tipos de Dados Pessoais que recolhemos.</li>
          <li>Coluna 3 - Como e por que os tratamos? Esta coluna explica o que fazemos com os seus Dados Pessoais e as finalidades da sua recolha.</li>
          <li>Coluna 4 - Qual é a base legal para tratar os seus Dados Pessoais? Esta coluna explica o motivo pelo qual podemos tratar os seus Dados Pessoais.</li>
        </ul>
        <p className="box-border mb-4">
          Quando recolhemos dados por meio de formulàrios, incluindo formulàrios electrónicos, indicaremos os campos obrigatórios por meio de asteriscos. O não fornecimento de dados marcados com um asterisco pode impedi-lo de aceder a um serviço.
        </p>
        <p className="box-border mb-4">
          Como Utilizador dos nossos serviços e dos nossos websites, hà muitas maneiras de partilhar os seus dados pessoais connosco e nós podermos recolhê-los.
        </p>
        <p className="box-border mb-4">
          Às vezes, fornece-nos os seus Dados Pessoais diretamente (por exemplo, quando nos contacta através dos nossos websites, quando solicita uma cotação, quando solicita um dos nossos serviços), outras recolhemos indiretamente (por exemplo, usando cookies para entender como utiliza os nossos websites) ou recebemos os seus dados através de terceiros, incluindo outras entidades do Bureau Veritas.
        </p>
        <table className="w-full p-2 mx-auto mb-20 text-justify border-2 border-black">
          <tbody className="border-2 border-black">
            <tr className="border-2 border-black">
              <td className="px-2 font-bold border-2 border-b border-black">Em que contexto os seus Dados Pessoais são recolhidos?</td>
              <td className="px-2 font-bold border-2 border-black">Que tipo de Dados Pessoais podemos manter sobre si?</td>
              <td className="px-2 font-bold border-2 border-black">Como e porque os tratamos?</td>
              <td className="px-2 font-bold border-2 border-black">Qual é a base legal para tratar os seus Dados Pessoais?</td>
            </tr>
            <tr className="border-2 border-black">
              <td className=" border-2 h-full relative w-[250px] border-black">
                <div className="absolute top-0 mx-2">
                  <b>Pedido de um serviço</b>
                  <p>Informação recolhida durante o pedido de um dos nossos serviços</p>
                </div>
              </td>
              <td className="border-2 h-full relative w-[250px] border-black">
                <ul className="absolute top-0 pl-6 mx-2 list-disc">
                  <li>Nome e apelido</li>
                  <li>Email</li>
                  <li>Morada para (facturação)</li>
                  <li>Número de telefone</li>
                  <li>Informação da transacção (detalhes sobre o serviço subscrito, número de transacção, histórico dos serviços, etc.)</li>
                  <li>Informação de pagamento</li>
                </ul>
              </td>
              <td className="border-2 h-full relative w-[250px] border-black">
                <div className="top-0 mx-2">
                  <p>Para</p>
                  <ul className="pl-6 list-disc">
                    <li>Enviar-lhe uma estimativa de orçamento</li>
                    <li>Tratar e seguir o seu pedido</li>
                    <li>Gerir o pagamento do seu pedido</li>
                    <li>Gerir qualquer contacto que tenha connosco sobre o seu pedido</li>
                    <li>Gerir qualquer litígio relacionado com o seu pedido</li>
                    <li>Executar estatísticas</li>
                  </ul>
                </div>
              </td>
              <td className="p-2 relative border-2 w-[250px] border-black">
                <ul className="absolute top-0 pl-6 mx-2 list-disc">
                  <li><b>Execução de um contrato:</b><br />
                    Para lhe fornecer o serviço que solicitou (pedido)
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <td className="border-2 h-full relative w-[250px] border-black">
                <div className="absolute top-0 mx-2">
                  <b>Pedido de um serviço</b>
                  <p>Informações recolhidas durante a prestação de serviços que solicitou</p>
                </div>
              </td>
              <td className="border-2 h-full relative w-[250px] border-black">
                <ul className="top-0 pl-6 mx-2 list-disc ">
                  <li>Nome e apelido</li>
                  <li>Email</li>
                  <li>Número de telefone</li>
                  <li>Dados relacionados com a relação comercial: dados sobre o serviço prestado, , duração, correspondência com o cliente, etc.)</li>
                  <li>Dados de identificação que permitem informações da conta pessoal para aceder a alguma das nossas plataformas ou aplicativos</li>
                </ul>
              </td>
              <td className="border-2 h-full relative w-[250px] border-black">
                <div className="absolute top-0 mx-2">
                  <p>Para</p>
                  <ul className="pl-6 list-disc">
                    <li>Realizar o serviço pedido</li>
                    <li>Permitir-lhe o acesso e utilização das plataformas quando o serviço for pedido</li>
                    <li>Gerir qualquer contacto que tenha connosco durante a prestação do serviço</li>
                    <li>Gerir a relação comercial</li>
                    <li>Gerir qualquer solicitação ou litígio relacionado com o serviço</li>
                  </ul>
                </div>
              </td>
              <td className="border-2 h-full relative w-[250px] border-black">
                <ul className="absolute top-0 pl-6 mx-2 list-disc">
                  <li><b>Execução de um contrato:</b><br />
                    Para lhe prestar o serviço que solicitou (pedido)
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <td className="border-2 h-full relative w-[250px] border-black">
                <div className="absolute top-0 mx-2">
                  <b>Pedido de um serviço</b>
                  <p>Informações recolhidas durante a prestação de serviços que solicitou</p>
                </div>
              </td>
              <td className="border-2 h-full relative w-[250px] border-black">
                <ul className="absolute top-0 pl-6 mx-2 list-disc ">
                  <li>Nome e apelido</li>
                  <li>Email</li>
                  <li>Função</li>
                  <li>Nome da Empresa</li>
                  <li>Número de telefone</li>
                </ul>
              </td>
              <td className="border-2 h-full relative w-[250px] border-black">
                <div className="absolute top-0 mx-2">
                  <p>Para</p>
                  <ul className="pl-6 list-disc">
                    <li>Lhe enviar comunicações comerciais (quando as tenha solicitado)</li>
                    <li>Manter uma lista de exclusão actualizada caso tenha pedido para não ser contactado</li>
                    <li>Fazer anàlises ou recolhas estatísticas</li>
                  </ul>
                </div>

              </td>
              <td className="border-2 h-full relative w-[250px] border-black">
                <div className="top-0 mx-2">
                  <ul className="pl-6 list-disc">
                    <b>Consentimento:</b>
                    <li>Nenhuma comunicação de marketing directo lhe é enviada se não consentiu em recebê-la</li>
                    <b>Interesse legítimo:</b>
                    <li>Para adaptar as nossas comunicações de marketing e entender a sua eficàcia, para conhecer as suas necessidades e melhorar os nossos serviços, para desenvolver o nosso negócio</li>
                    <b>Cumprimento de uma obrigação legal:</b>
                    <li>Para manter os seus dados numa lista de exclusão caso tenha pedido para não enviar mais marketing directo</li>
                  </ul>
                </div>
              </td>
            </tr>
            <tr className="border-2 h-full relative w-[250px] border-black">
              <td className="border-2 h-full relative w-[250px] border-black">
                <div className="absolute top-0 mx-2">
                  <b>Navegação online</b>
                  <p>Informações recolhidas por cookies ou por tecnologias similares (\"Cookies\") como parte da sua navegação nos websites do Bureau Veritas.
                    * Cookies são pequenos arquivos de texto armazenados no seu dispositivo (computador, tablet e telemóvel) quando se encontra na Internet.</p>
                </div>
              </td>
              <td className="border-2 h-full relative w-[250px] border-black">
                <div className="absolute top-0 mx-2">
                  <ul className="pl-6 list-disc">
                    <p>Dados relacionados com o uso do nosso website:</p>
                    <li>De onde é</li>
                    <li>Pàginas que visitou</li>
                    <li>Duração da sua visita</li>
                    <p>Informação técnica:</p>
                    <li>Endereço de IP</li>
                    <li>Informação de browser</li>
                    <li>Informação de dispositivo</li>
                  </ul>
                </div>
              </td>
              <td className="border-2 h-full relative w-[250px] border-black">
                <ul className="px-2 pl-6 list-disc">
                  <li>Para lhe personalizar os nossos serviços:
                    Para enviar recomendações ou comunicações de marketing baseadas nos seus interesses
                    Para exibir o nosso website de maneira personalizada</li>
                  <li>Para permitir o bom funcionamento do nosso website:
                    Exibição adequada de conteúdo
                    Personalização de interface, como o idioma
                    Parâmetros anexados ao dispositivo incluindo a resolução do seu ecrã
                    Melhoria do nosso website, por exemplo testando novas ideias.</li>
                  <li>Para garantir que o website seja seguro e para protegê-lo contra fraudes ou uso indevido dos nossos websites ou serviços</li>
                  <li>Para fazer estatísticas:
                    Para melhorar as nossas ofertas
                    Para saber como descobriu o nosso website</li>
                </ul>
              </td>
              <td className="border-2 h-full relative w-[250px] border-black">
                <ul className="absolute top-0 px-2 pl-6 list-disc">
                  <li><b>Interesse legítimo:</b></li>
                  <p>Para garantir que lhe estamos fornecendo websites e comunicações que funcionam adequadamente, que sejam seguros e protegidos e em melhoria contínua, para o desenvolvimento do nosso negócio</p>
                  <li><b>
                    Consentimento:</b></li>
                  <p>Para os cookies que não são necessàrios para o funcionamento do nosso website</p>
                </ul>
              </td>
            </tr>
            <tr className="border-2 h-full relative w-[250px] border-black">
              <td className="border-2 h-full relative w-[250px] border-black">
                <div className="absolute top-0 mx-2">
                  <b>Consultas</b>
                  <p>Informações recolhidas quanto nos questiona acerca dos nossos serviços</p>
                </div>
              </td>
              <td className="border-2 h-full relative w-[250px] border-black">
                <div className="absolute top-0 mx-2">
                  <ul className="pl-6 list-disc">
                    <li>Nome e apelido</li>
                    <li>Email</li>
                    <li>Função</li>
                    <li>Nome da Empresa</li>
                    <li>Número de telefone</li>
                    <li>Outras informações que partilhou connosco acerca da sua consulta</li>
                  </ul>
                </div>
              </td>
              <td className="border-2 h-full relative w-[250px] border-black">
                <div className="absolute top-0 mx-2">
                  <ul className="pl-6 list-disc">
                    <li>Para responder às suas solicitações</li>
                    <li>Quando necessàrio, para o colocar em contacto com os nossos serviços relevantes</li>
                    <li>Para fins estatísticos</li>
                    <li>Para lhe enviar orçamento quando o solicita</li>
                  </ul>
                </div>
              </td>
              <td className="border-2 h-full relative w-[250px] border-black">
                <div className="top-0 mx-2">
                  <ul className="pl-6 list-disc">
                    <li><b>Consentimento:</b></li>
                    <p>Para processar e tratar o seu pedido</p>
                    <li><b>Execução de um contrato:</b></li>
                    <p>Para lhe fornecer as informações solicitadas no contexto do contrato entre si e o Bureau Veritas</p>
                    <li><b>Interesse legítimo:</b></li>
                    <p>Para nos ajudar a melhor entender as necessidades e expectativas dos nossos “Utilizadores” e portanto melhorar os nossos serviços</p>
                  </ul>
                </div>
              </td>
            </tr>
            <tr>
              <td className="border-2 h-full relative w-[250px] border-black">
                <div className="absolute top-0 mx-2">
                  <b>Candidatura a uma vaga</b>
                  <p>Informações recolhidas quanto se candidata a um emprego no Bureau Veritas</p>
                </div>
              </td>
              <td className="border-2 h-full relative w-[250px] border-black">
                <ul className="pl-6 list-disc ">
                  <li>Informação de identificação</li>
                  <li>Detalhes de contacto</li>
                  <li>Curriculum vitae (qualificações, empregos anteriores, etc.);</li>
                  <li>Notas da entrevista</li>
                </ul>
              </td>
              <td className="border-2 h-full relative w-[250px] border-black">
                <div className="px-2 ">

                  <p>Para:</p>
                  <ul className="pl-6 list-disc ">
                    <li>Analisar os pedidos dos candidatos</li>
                    <li>Gerir os processos de recrutamento</li>
                    <li>Contratar os candidatos</li>
                  </ul>
                </div>
              </td>
              <td className="border-2 h-full relative w-[250px] border-black">
                <div className="top-0 mx-2">
                  <ul className="pl-6 list-disc">
                    <li><b>Consentimento:</b></li>
                    <p>Para tratar a sua candidatura</p>
                    <li><b>Interesse legítimo:</b></li>
                    <p>Para encontrar os melhores candidatos para as nossas ofertas de emprego</p>
                  </ul>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <p className="box-border my-4">
          Bureau Veritas não trata os seus Dados Pessoais com outras finalidades, que não sejam as descritas nesta Política de Protecção de Dados Pessoais e que sejam diferentes daquelas sobre as quais foi informado.
        </p>
        <p className="box-border mb-4 underline">
          <b>3. Notificação e consentimento</b>
        </p>
        <p className="box-border my-4">
          Em certas circunstâncias, conforme estabelecido na tabela supra, solicitamos o seu consentimento antes de recolher, tratar ou divulgar os seus Dados Pessoais, em particular quando:
        </p>
        <ul className="pl-5 mb-4 list-disc">
          <li>Pretende subscrever a nossa newsletter ou receber comunicações comerciais;</li>
          <li>Usamos cookies não essenciais ou tecnologia semelhante a cookies e / ou recolhemos informações sobre o dispositivo que utiliza para aceder aos nossos websites;</li>
          <li>Quando nos questiona sobre os nossos serviços;</li>
          <li>Quando se candidata a um emprego no Bureau Veritas.</li>
        </ul>
        <p className="box-border mb-4">
          Ao recolhemos o seu consentimento prévio, é informado nesse momento da recolha dos seus Dados Pessoais. Pode retirar o seu consentimento a todo o momento usando o mecanismo de opt-out fornecido e indicado no momento da recolha dos seus Dados Pessoais, ou entrando em contacto connosco através dos dados de contacto fornecidos no Artigo 9 \"Contacto\" desta Política de Protecção de Dados Pessoais.
        </p>
        <p className="box-border mb-4 underline">
          <b>4. Quando divulgamos os seus Dados Pessoais?</b>
        </p>
        <p className="box-border mb-4">
          Alguns dos seus Dados Pessoais podem estar acessíveis:
        </p>
        <p className="box-border mb-4">
          - Dentro do Bureau Veritas e por qualquer outro membro do Grupo Bureau Veritas;<br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{">"} Isso só serà feito com base na necessidade de saber e, quando necessàrio, para lhe fornecer os serviços solicitados, ou no contexto de um contrato entre si e o Bureau Veritas, ou com o seu consentimento (em particular para fins comerciais).<br />
          - Por prestadores de serviços confiàveis actuando como subcontratantes (ou seja, subcontratantes) que realizarão certos serviços em nosso nome necessàrios para os fins indicados supra (serviços de marketing, serviços de hosting, manutenção de bases de dados, etc.).<br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{">"} Somente lhes fornecemos as informações necessàrias para executar tais serviços, e exigimos que não tratem os seus Dados Pessoais para nenhuma outra finalidade. Esses prestadores de serviço só agirão de acordo com as instruções do Bureau Veritas e estarão contratualmente obrigados a garantir um nível de segurança e confidencialidade para os seus Dados Pessoais que é igual ao que o Bureau Veritas é obrigado a garantir e a cumprir em conformidade com as leis e regulamentos de protecção de dados pessoais aplicàveis.
        </p>
        <p className="box-border mb-4">
          Além disso, o Bureau Veritas poderà partilhar os seus Dados Pessoais com terceiros:
        </p>
        <ul className="pl-5 mb-4 ">
          <li>- Para proteger os direitos, propriedade ou segurança do Bureau Veritas, dos nossos Utilizadores, dos nossos colaboradores ou dos outros; ou</li>
          <li>- No caso de uma fusão ou venda de activos da empresa (nesse caso, os seus dados pessoais serão divulgados ao potencial comprador); ou</li>
          <li>- Para cumprir uma obrigação legal ou responder a processos judiciais de qualquer natureza, ordens judiciais, qualquer acção legal ou medidas de execução exigidas pelas autoridades competentes; ou</li>
          <li>- Para outros fins exigidos pela legislação aplicàvel ou com o seu consentimento prévio.</li>
        </ul>
        <p className="box-border mb-4 underline">
          <b>5. Onde armazenamos os seus Dados Pessoais?</b>
        </p>
        <p className="box-border mb-4">
          Como o Grupo Bureau Veritas possui entidades em todo o mundo e uma vez que alguns dos nossos prestadores de serviços estão localizados no exterior, os dados que recolhemos sobre si podem ser transferidos de um país localizado dentro do Espaço Económico Europeu ('EEE') para um país localizado fora do EEE.
        </p>
        <p className="box-border mb-4">
          Quando o Bureau Veritas transfere os Dados Pessoais para fora do EEE, isso serà sempre feito de maneira segura e legal:
        </p>
        <ul className="pl-5 mb-4 list-disc">
          <li>Quer através da transferÃªncia dos Dados Pessoais para um destinatàrio dos dados localizado num país que seja objecto de uma decisão de adequação adoptada pela Comissão Europeia, estabelecendo que este terceiro país garante um nível adequado de protecção dos Dados Pessoais;</li>
          <li>Ou executando as Clàusulas Contratuais Padrão Europeias (assinadas entre o Bureau Veritas e suas subsidiàrias ou entre o Bureau Veritas e os seus prestadores de serviços) que foram aprovadas pela Comissão Europeia como fornecendo um nível adequado de protecção para os seus Dados Pessoais.</li>
        </ul>
        <p className="box-border mb-4 underline">
          <b>6. Durante quanto tempo os seus Dados Pessoais são mantidos?</b>
        </p>
        <p className="box-border mb-4">
          Manteremos os seus Dados Pessoais apenas durante o tempo necessàrio para as finalidades do tratamento para os quais foram recolhidos (normalmente, a duração do contrato). Podemos, no entanto, manter os seus dados por período de tempo mais longo em cumprimento com as disposições legais ou regulamentares específicas e / ou para cumprir com os prazos de prescrição aplicàveis. No caso de uma retenção de dados mais longa por outros motivos, informà-lo-emos sobre esses motivos e sobre o período de retenção aplicàvel ao recolher os seus Dados Pessoais.
        </p>
        <p className="box-border mb-4">
          Para determinar o período de retenção dos seus Dados Pessoais, usamos, em particular, os seguintes critérios:
        </p>
        <ul className="pl-5 mb-4 list-disc">
          <li>Ao solicitar um serviço, mantemos os seus Dados Pessoais enquanto durar a nossa relação comercial e de acordo com o prazo prescricional;</li>
          <li>Quando nos contacta para uma consulta, mantemos os seus dados pessoais pelo tempo necessàrio para o processamento da sua consulta;</li>
          <li>Quando deu o seu consentimento em acções de marketing directo, mantemos os seus Dados Pessoais até que cancele a subscrição ou solicite que o excluamos ou após um período de inactividade (sem interação activa connosco) definido de acordo com os regulamentos e directrizes locais;</li>
          <li>Quando os cookies são colocados no seu computador, (mantemo-los pelo tempo que for necessàrio para alcançar a sua finalidade) e por um período definido de acordo com os regulamentos e directrizes locais.</li>
          <li>Para os candidatos, mantemos os seus Dados Pessoais de acordo com a legislação aplicàvel.</li>
        </ul>
        <p className="box-border mb-4 underline">
          <b>7. Como são protegidos os seus Dados Pessoais?</b>
        </p>
        <p className="box-border mb-4">
          Estamos empenhados em manter os seus Dados Pessoais seguros e a tomar todas as precauções razoàveis para o fazer. Implementàmos todas as medidas organizativas e técnicas necessàrias de acordo com esta Política de Protecção de Dados Pessoais e leis e regulamentos aplicàveis para proteger os seus Dados Pessoais contra qualquer acesso não autorizado e modificação, divulgação, perda ou destruição. Exigimos contratualmente que os prestadores de serviço que lidam com os seus dados pessoais façam o mesmo.
        </p>
        <p className="box-border mb-4 underline">
          <b>8. Os seus direitos</b>
        </p>
        <p className="box-border mb-4">
          De acordo com as leis e regulamentos de Protecção de Dados Pessoais, beneficia de um conjunto de direitos em relação aos seus Dados Pessoais, a saber:
        </p>
        <ul className="pl-5 mb-4">
          <li> - <b>Direito de acesso e de informação:</b> tem o direito de ser informado de forma concisa, transparente, inteligível e de fàcil acesso sobre o modo como os seus Dados Pessoais são tratados. Tem também o direito de obter (i) confirmação sobre se os Dados Pessoais estão sendo tratados ou não e, se aplicàvel (ii) para aceder a tais Dados Pessoais e obter uma cópia dos mesmos.</li>
          <li> - <b>Direito de rectificação:</b>Direito de rectificação: tem o direito de obter a rectificação de dados pessoais imprecisos. Tem também o direito completar os Dados Pessoais que estejam incompletos, inclusive fornecendo uma declaração complementar.</li>
          <li> - <b>Direito ao apagamento (\"direito de ser esquecido\"):</b> em alguns casos, tem o direito de obter o apagamento dos seus Dados Pessoais. No entanto, isso não é um direito absoluto e o Bureau Veritas pode ter razões legais ou legítimas para manter tais Dados Pessoais.</li>
          <li> - <b>Direito à restrição de tratamento:</b> em alguns casos, tem o direito de obter restrição sobre o tratamento dos seus Dados Pessoais.</li>
          <li> - <b>Direito à portabilidade de dados:</b> tem o direito de receber os Dados Pessoais que forneceu ao Bureau Veritas, em um formato estruturado, comummente usado e legível informaticamente, e tem o direito de transmitir esses dados para outro responsàvel pelo tratamento sem impedimentos por parte do Bureau Veritas. Este direito aplica-se apenas quando o tratamento dos seus Dados Pessoais se baseia no seu consentimento ou num contrato e esse tratamento é efectuado por meios automàticos.</li>
          <li> - <b>Direito de se opor ao tratamento:</b> tem o direito de se opor a qualquer momento ao tratamento dos seus Dados Pessoais. Quando exerce o seu direito de se opor, por motivos relacionados à sua situação particular, a um tratamento baseado no interesse legítimo do Bureau Veritas, o Bureau Veritas pode, no entanto, invocar razões legítimas convincentes para continuar o tratamento.</li>
          <li> - <b>Direito de revogar o seu consentimento, a qualquer momento, para o tratamento com base no seu consentimento:</b> Pode revogar o seu consentimento para o tratamento dos seus Dados Pessoais quando tal tratamento for baseado no seu consentimento. A revogação do consentimento não afecta a legalidade do tratamento realizado com base em tal consentimento antes da revogação do consentimento.</li>
          <li> - <b>Direito de apresentar queixa junto à autoridade supervisora:</b> tem o direito de entrar em contacto com a Comissão Nacional de Protecção de Dados para reclamar sobre as pràticas de protecção de dados pessoais do Bureau Veritas.
          </li>
          <li> - <b>Direito de dar instruções sobre o uso dos seus dados após a sua morte:</b> tem o direito de instruir o Bureau Veritas sobre o uso dos seus dados pessoais após a sua morte.
          </li>
          <li> - <b>Direito de obter uma cópia das clàusulas contratuais padrão</b> assinadas pelo Bureau Veritas quando os seus Dados Pessoais são transferidos para fora do EEE.
          </li>
        </ul>
        <p className="box-border mb-4">
          Para mais informações sobre os seus direitos e como exercê-los, entre em contacto connosco conforme indicado na secção 9 desta Política de Protecção de Dados Pessoais.
        </p>
        <p className="box-border mb-4">
          Por favor note que poderemos exigir prova da sua identidade e todos os detalhes do seu pedido antes de processà-lo.
        </p>
        <p className="box-border mb-4 underline">
          <b>9. Contacto</b>
        </p>
        <p className="box-border pb-10 mb-4">
          Se tiver dúvidas sobre como tratamos e utilizamos os seus Dados Pessoais, ou se pretender exercer quaisquer dos seus direitos acima, entre em contacto com o Encarregado de Protecção de Dados e os que localmente com este se relacionam em <a className="hover:text-blue-800" href=" https://personaldataprotection.bureauveritas.com." target="_blank"> https://personaldataprotection.bureauveritas.com.</a>
        </p>
      </section>
    </main>
  )
}