import { Link } from "react-router-dom"

const mapButtons = [
  {
    name: 'Termos de uso',
    path: '/legal/termos-de-uso'
  },
  {
    name: 'Aviso Legal',
    path: '/legal/aviso-legal'
  },
  {
    name: 'Política de cookies',
    path: '/legal/politicas-de-cookies'
  },
  {
    name: 'Aviso de informação',
    path: '/legal/aviso-de-informaçao'
  },
  {
    name: 'Política global de proteção de dados',
    path: '/legal/politicas-globais'
  },
  {
    name: 'Política de proteção de dados LGPD',
    path: '/legal/politicas-lgpd'
  }
]

export default function Footer() {
  return (
    <footer className="fixed bottom-0 w-full h-[4.375rem] bg-white shadow-2xl">
      <ul className="flex pl-8 pb-1 items-center justify-evenly h-full text-xs text-[#847C6D] xl:text-base">
        {mapButtons.map(({ name, path }, index) => (
          <Link key={name + index} target="_blank" to={path}>{name}</Link>
        ))}
      </ul>
    </footer>
  )
}