export default function InfoAdvices() {
  return (
    <main className="w-full flex py-12 justify-center h-screen overflow-auto bg-[#ebebeb]">
      <section className="max-w-[540px] text-[#212529] md:max-w-[720px] lg:max-w-[960px] xl:max-w-[1140px]">
        <h1 className="text-center  mb-12 text-[2.5rem] font-medium">Aviso de Informação</h1>
        <p className="box-border mb-4">
          Os seus dados pessoais são recolhidos pela Inspectorate International Limited  são publicados pela empresa DEVIO SOFTWARE INTELIGENTES LTDA, registrada no Registro Comercial de São Paulo/SP - Brasil sob o número 32.973.740/0001-09 com sede na Avenida Brig Faria Lima, 2369, Conj 1102 - Jardim Paulistano, São Paulo/SP. - CEP 01452-922 e estão sujeitos a processamento informatizado para gerenciar, acompanhar e acompanhar os pedidos de venda (proposta) de CTD, de acordo com a execução e cumprimento do seu contrato de trabalho do Bureau Veritas.
        </p>
        <p className="box-border mb-4">
          A Inspectorate International Limited recolhe e trata as seguintes categorias de dados pessoais: identificação (primeiro nome, apelido), vida profissional (ID de e-mail). Esses dados vêm do OMS, eles não são originários de fontes publicamente disponíveis.
        </p>
        <p className="box-border mb-4">
          Os seus dados pessoais destinam-se ao departamento de Vendas e Marketing, departamento de SI. Eles serão retidos por um período de tempo em que sua conta estiver ativa.
        </p>
        <p className="box-border mb-4">
          Os seus dados pessoais serão transferidos para fora da União Europeia, com base em cláusulas contratuais padrão estabelecidas pela Comissão Europeia, disponíveis mediante solicitação, preenchendo uma solicitação em https://personaldataprotection.bureauveritas.com.
        </p>
        <p className="box-border mb-4">
          Os campos marcados com um asterisco devem ser preenchidos. Caso contrário, a Inspectorate International Limited não poderá fornecer os serviços solicitados.
        </p>
        <p className="box-border mb-4">
          De acordo com a Lei Francesa de Proteção de Dados de 6 de janeiro de 1978 alterada e o Regulamento Geral de Proteção de Dados de 27 de abril de 2016, você tem o direito de acessar, retificar e apagar quaisquer dados pessoais que lhe digam respeito, bem como o direito de limitar o processamento, o direito de oposição ao tratamento ou o direito à portabilidade dos seus dados pessoais. Você também tem o direito de estabelecer diretrizes gerais e específicas que definam como você pretende que esses direitos sejam exercidos após sua morte. Você pode exercer seus direitos por e-mail no seguinte endereço: https://personaldataprotection.bureauveritas.com. Finalmente, você tem o direito de apresentar uma reclamação à Comissão Nacional de Informação e Liberdade (CNIL).
        </p>
      </section>
    </main>
  )
}