export default function CookiePolitcs() {

  return (
    <main className="w-full flex  justify-center h-screen overflow-auto bg-[#ebebeb]">
      <section className="max-w-[540px] py-12 text-[#212529] md:max-w-[720px] lg:max-w-[960px] xl:max-w-[1140px]">
        <h1 className="text-center  mb-12 text-[2.5rem] font-medium">Política de Cookies</h1>
        <p className="box-border mb-4">
          <b>USO DE COOKIES PELO BUREAU VERITAS</b>
        </p>
        <p className="box-border mb-4">
          Informamos que a empresa Bureau Veritas ("Bureau Veritas", "nós") utiliza cookies ou outros rastreadores nos sites&nbsp;
          <a
            className="hover:text-blue-800"
            title="Site https://auditor.bureauveritas.com.br/"
            href="https://auditor.bureauveritas.com.br/"
            target="_blank"
            rel="noreferrer"
          >
            https://auditor.bureauveritas.com.br/
          </a>,
          <a
            className="hover:text-blue-800"
            title="Site https://cliente.bureauveritas.com.br/"
            href="https://cliente.bureauveritas.com.br/"
            target="_blank"
            rel="noreferrer"
          >
            https://cliente.bureauveritas.com.br/
          </a>,
          <a
            className="hover:text-blue-800"
            title="Site https://admin.bureauveritas.com.br/"
            href="https://admin.bureauveritas.com.br/"
            target="_blank"
            rel="noreferrer"
          >
            https://admin.bureauveritas.com.br/
          </a>&nbsp;e&nbsp;
          <a
            className="hover:text-blue-800"
            title="Site https://fabrica.bureauveritas.com.br/"
            href="https://fabrica.bureauveritas.com.br/"
            target="_blank"
            rel="noreferrer"
          >
            https://fabrica.bureauveritas.com.br/
          </a>
          . Estes podem ser instalados em seu terminal de acordo com as escolhas e opções que você fez ou podem ser expressos a qualquer momento de acordo com esta política.
        </p>
        <p className="box-border mb-4">
          O Bureau Veritas definiu esta política para sua informação e transparência, para que você possa saber mais sobre:
        </p>
        <ul className="pl-5 mb-4 list-disc">
          <li>A origem e finalidade das informações processadas quando você navega no Site;</li>
          <li>Seus direitos sobre cookies e outros rastreadores usados pelo Bureau Veritas.</li>
        </ul>
        <p className="box-border mb-4">
          <b>O QUE É UM COOKIE?</b>
        </p>
        <p className="box-border mb-4">
          Cookies, ou rastreadores semelhantes, são dados usados ​​por um servidor para enviar informações de status ao navegador de um usuário e por esse navegador para enviar informações de status de volta ao servidor original.
        </p>
        <p className="box-border mb-4">
          As informações de status podem ser, por exemplo, um ID de sessão, idioma, data de validade etc.
        </p>
        <p className="box-border mb-4">
          Os cookies são usados ​​para armazenar informações de status durante sua validade quando um navegador acessa diferentes páginas em um site ou quando o navegador retorna posteriormente a esse site.
        </p>
        <p className="box-border mb-4">
          <b>PARA QUE SERVEM OS COOKIES EMITIDOS NO SITE?</b>
        </p>
        <p className="box-border mb-4">
          Utilizamos cookies e outros rastreadores, em particular para as seguintes finalidades principais:
        </p>
        <p className="box-border mb-4">
          <b>Os cookies são estritamente necessários para facilitar a navegação no Site</b>
        </p>
        <p className="box-border mb-4">
          Estes cookies têm em particular vocação para:
        </p>
        <ul className="pl-5 mb-4 list-disc">
          <li>Otimizar a experiência do utilizador e facilitar a navegação, nomeadamente determinando "rotas técnicas" de navegação;</li>
          <li>permitindo assim a inserção de cookies no seu terminal.</li>
        </ul>
        <p className="box-border mb-4">
          <b>Cookies de funcionalidades</b>
        </p>
        <p className="box-border mb-4">
          Estes cookies têm notadamente vocação para:
        </p>
        <ul className="pl-5 mb-4 list-disc">
          <li>Adaptar o Site às preferências de exibição de seu terminal (idioma, moeda, resolução de exibição, sistema operacional utilizado, configuração e configuração da exibição de páginas da web de acordo com o terminal que você está usando e sua localização, etc.);</li>
          <li>Memorizar certas informações que você insere no Site para facilitar e personalizar sua próxima navegação.</li>
        </ul>
        <p className="box-border mb-4">
          <b>Cookies usados ​​para rastrear o uso do Site pelos usuários (medição de audiência)</b>
        </p>
        <p className="box-border mb-4">
          Esses cookies nos ajudam a entender suas interações com o Site (páginas mais visitadas, aplicativos usados, etc.); esses cookies podem ser usados ​​para compilar estatísticas ou testar diferentes exibições para melhorar o interesse e a ergonomia de nossos serviços.
        </p>
        <p className="box-border mb-4">
          <b>Cookies de redes sociais (colocados por terceiros)</b>
        </p>
        <p className="box-border mb-4">
          Esses cookies permitem que você compartilhe sua opinião no Site e compartilhe conteúdo nas redes sociais (por exemplo, botões de aplicativos "Compartilhar" ou "Gosto" das redes sociais).
        </p>
        <p className="box-border mb-4">
          Os aplicativos de redes sociais no Site, conforme mencionado acima, podem, em alguns casos, permitir que as redes sociais correspondentes o identifiquem, mesmo que você não tenha clicado nos botões do aplicativo.
        </p>
        <p className="box-border mb-4">
          De fato, este tipo de botão pode permitir que a rede social em questão acompanhe sua navegação no Site, apenas porque sua conta na rede social em questão é ativada em seu dispositivo (sessão aberta) durante sua navegação. Recomendamos que você revise as políticas dessas redes sociais para estar ciente das finalidades de uso, incluindo publicidade, das informações de navegação que podem coletar. Essas políticas devem permitir que você exerça suas escolhas com essas redes sociais, em particular configurando suas contas de uso para cada uma delas
        </p>
        <p className="box-border mb-4">
          <b>COOKIES SUJEITOS A SEU CONSENTIMENTO</b>
        </p>
        <p className="box-border mb-4">
          A instalação de determinados cookies está sujeita ao seu consentimento. Além disso, quando você visita o Site pela primeira vez, é solicitado, pela presença de um banner informativo na página inicial do Site, se você aceita a instalação desse tipo de cookie. Os cookies só serão ativados após sua aceitação, continuando sua navegação no Site.
        </p>
        <p className="box-border mb-4">Você poderá retornar à sua escolha a qualquer momento pelos vários meios descritos na seção "Como gerenciar cookies?</p>
        <p className="box-border mb-4">
          <b>COMO GERIR COOKIES?</b>
        </p>
        <p className="box-border mb-4">
          Existem várias maneiras de gerenciar cookies e outros rastreadores.
        </p>
        <p className="box-border mb-4">
          Você pode configurar seu navegador para:
        </p>
        <ul className="pl-5 mb-4 list-disc">
          <li>Aceitar todos os cookies, ou</li>
          <li>Rejeitá-los sistematicamente, ou</li>
          <li>Escolha os que você aceita de acordo com seus emissores.</li>
        </ul>
        <p className="box-border mb-4">
          Você também pode configurar seu navegador para aceitar ou recusar cookies caso a caso antes de serem instalados. Você também pode excluir cookies regularmente do seu dispositivo por meio do navegador.
        </p>
        <p className="box-border mb-4">
          Não se esqueça de configurar todos os navegadores dos seus diferentes terminais (tablets, smartphones, computadores...).
        </p>
        <p className="box-border mb-4">
          Para a gestão de cookies e suas escolhas, a configuração de cada navegador é diferente. Ele está descrito no menu de ajuda do seu navegador, que permitirá que você saiba como alterar suas preferências de cookies.
        </p>
        <ul className="pl-5 mb-4 underline list-disc">
          <li><b>Para Internet Explorer</b></li>
          <li><b>Para Safari</b></li>
          <li><b>Para Chrome</b></li>
          <li><b>Para Firefox</b></li>
          <li><b>Para Ópera</b></li>
        </ul>
        <p className="box-border mb-4">
          O registo de um cookie no seu terminal está essencialmente sujeito à sua vontade, que pode expressar e modificar a qualquer momento e gratuitamente através das opções oferecidas pelo seu navegador.
        </p>
        <p className="box-border mb-4">
          Se o seu navegador estiver configurado para aceitar cookies no seu dispositivo, os cookies incorporados nas páginas e conteúdo que você visualizou podem ser armazenados temporariamente em uma área dedicada do seu dispositivo. Eles só serão legíveis pelo seu transmissor.
        </p>
        <p className="box-border pb-12 mb-4">
          Por outro lado, você pode configurar seu navegador para recusar cookies. Nesse sentido, chama-se a atenção para o fato de que, ao configurar seu navegador para recusar cookies, determinados recursos, páginas e espaços do Site não estarão acessíveis (por exemplo, se você tentar acessar nossos conteúdo ou serviços que requeiram sua identificação, ou se nós ou nossos provedores de serviços não pudermos reconhecer, para fins de compatibilidade técnica, o tipo de navegador usado pelo seu terminal, suas configurações de idioma e exibição ou o país de localização do seu terminal). Não podemos ser responsabilizados pelas consequências do funcionamento degradado dos nossos serviços resultantes da impossibilidade de registarmos ou consultarmos os cookies necessários ao seu funcionamento e que teria recusado ou eliminado.
        </p>
      </section>
    </main>
  )
}